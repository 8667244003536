import React, {Component} from 'react';
import Header from "../Components/Header";
import Maps from "../Components/Maps";
import Footer from "../Components/Footer";

export default class Contact extends Component {
    render() {
        return (
            <div>
                <Header />
            <div className='contactContainer' style={{
                fontFamily: 'Playfair Display'
            }}>

                <div className="bContacts" style={{
                    marginTop:'8%'
                }}>
                    <div className="fContact">
                        <p>Контакты</p>
                    </div>
                    <div className='footer1'>
                        <div className="elmFooter">
                            <p>Местонахождение</p>
                            <p>Россия, Краснодар</p>
                        </div>
                        <div className="elmFooter">
                            <p>Номер телефона и почта</p>
                            <p>+7(903)456-07-01</p>
                            <p>rusbluecatkrd@gmail.com</p>
                        </div>
                        <div className="elmFooter">
                            <p>Здесь должен быть Qr-код</p>
                        </div>
                    </div>
                </div>

            </div>
                <Maps />
                <Footer />
            </div>
        );
    }
}

