import React, {Component} from 'react';
import {Carousel, CarouselItem} from "react-bootstrap";
import cats1 from './img/catsCarousel1.png'
import cats2 from './img/catsCarousel2.png'
import cats3 from './img/catsCarousel3.png'
import cats4 from './img/catsCarousel4.png'

export default class CarouselBox extends Component {
    render() {
        return (
            <Carousel className="carouselBody" style={{

            }}>
                <CarouselItem className="cItems">
                    <img
                        className="carouselImg"
                        src={cats1}
                        alt="First slide"

                    />

                </CarouselItem>
                <CarouselItem className="cItems">
                    <img
                        className="carouselImg"
                        src={ cats2 }
                        height='800px'
                        alt="Second slide"

                    />

                </CarouselItem>
                <CarouselItem className="cItems">
                    <img
                        className="carouselImg"
                        src={ cats3 }
                        height='800px'
                        alt="Third slide"

                    />

                </CarouselItem>
                <CarouselItem className="cItems">
                    <img
                        className="carouselImg"
                        src={ cats4 }
                        height='800px'
                        alt="Four slide"

                    />

                </CarouselItem>
            </Carousel>
        );
    }
}

