import React, {Component} from 'react';
import Header from "../Components/Header";
import {Button} from "react-bootstrap";
import cat from "../Components/img/cats2.png"
import { Link} from "react-router-dom";
import cat1 from "../Components/img/catsMenuG.png"
import cat2 from "../Components/img/catsMenuD.png"
import Maps from "../Components/Maps";
import Footer from "../Components/Footer";
import CarouselBox from "../Components/CarouselBox";



export default class Home extends Component {
    render() {
        return (
            <div className="homePage" style={{
                fontFamily: 'Playfair Display',
                width: '100%'
            }}>
                <Header/>
                <CarouselBox/>
                <div className='textBL'>
                    <p>ДОБРО ПОЖАЛОВАТЬ В ПИТОМНИК "BLUE SHINE"!</p>
                </div>
                <div className="cAbout">
                    <div className="tAbout">
                        <h3>О НАС</h3>
                        <p>
                            Питомник "Blue shine" работает с 2012 года. Цель нашего питомника - подарить вам здорового,
                            хорошо социализированного котенка, который станет частью вашей семьи и компаньоном на долгие
                            годы.
                        </p>
                        <Link className="btnLink" to="/About">
                        <Button className="btnLink" style={{
                            color:'#2A7C6F',
                            backgroundColor:'#bfd0d3',
                            borderColor:'#2A7C6F',

                        }}>
                            Читать дальше
                        </Button>
                        </Link>
                    </div>
                    <div>
                        <img
                            className="cat"
                            src={cat}
                            alt='cat'
                        />
                    </div>
                </div>
                <div className="menu">
                    <div>
                        <Link to="/Gallery" target="_parent" rel="noreferrer" >
                            <figure className="position-relative">
                                <img
                                    className="cat1"
                                    src={cat1}
                                    alt="example"
                                />
                                <figcaption className="homeCaption" >
                                    Галерея
                                </figcaption>
                            </figure>
                        </Link>
                    </div>
                    <div>
                        <Link to="/Documents" target="_parent" rel="noreferrer">
                            <figure className="position-relative">
                                <img
                                    className="cat2"
                                    src={cat2}
                                    alt="example"
                                />
                                <figcaption className="homeCaption">
                                    Документы
                                </figcaption>
                            </figure>
                        </Link>
                    </div>
                </div>
                <div>
                    <div className="fContact">
                        <p>Контакты</p>
                    </div>
                    <div className='footer'>
                        <div className="elmFooter">
                            <p>Местонахождение</p>
                            <p>Россия, Краснодар</p>
                        </div>
                        <div className="elmFooter">
                            <p>Номер телефона и почта</p>
                            <p>+7(903)456-07-01</p>
                            <p>rusbluecatkrd@gmail.com</p>
                        </div>
                        <div className="elmFooter">
                            <p>Здесь должен быть Qr-код</p>
                        </div>
                    </div>
                </div>
                <div className="homeMaps">
                    <Maps/>
                </div>
                <Footer/>
            </div>
        );
    }
}

