import React, {Component} from 'react';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import cat1 from "../Components/img/catsAbout2.png";
import cat2 from "../Components/img/catsAbout1.png"
import cats3 from  "../Components/img/catA.png"

export default class About extends Component {
    render() {
        return (
            <div className="aboutBody">
                <Header/>
                <div className="About">
                    <div>
                        <img
                            className="catA"
                            src={cats3}
                            alt='cat'
                        />
                    </div>
                    <div className="hAbout">

                        <h3 style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}>О НАС</h3>
                        <p>
                            История нашего питомника начинается с 2012 года, когда наша семья решила завести
                            четвероногого маленького друга. Мы присматривались к разным породам, но долго не могли найти
                            ту, что попадёт в самое сердце. И, вот, нам наконец улыбается удача и мы находим ту самую
                            идеальную кошку. Что покорило наши сердца: элегантный, располагающий экстерьер – длинное,
                            грациозно сложенное мускулистое тело, короткая и чрезвычайно нежная шерсть с чистым голубым
                            окрасом, и, конечно, миндалевидные глаза изумрудного цвета. Не оставил равнодушным также
                            характер малышки – ласковая, общительная, умная, игривая. Все те качества, которые так
                            ценятся семейными людьми. Так, в нашей семье появилась Весея (C-Veseya Bluetsar*ru), будущая
                            чемпионка по системе FIFe и основоположница нашего небольшого питомника «BLUE SHINE»
                            -«Голубое сияние». С тех пор прошло много лет, но наша любовь к русским голубым с каждым
                            днём становится только сильнее. В настоящий момент наш питомник представлен двумя котами
                            породного типа. Питомник «BLUE SHINE» лицензирован в Международной фелинологической
                            федерацией — WCF. Все коты питомника посещают выставки, имеют титульные сертификаты и
                            получают высшие оценки от судей. Цель нашего питомника - подарить вам здорового, хорошо
                            социализированного котенка, который станет частью вашей семьи и компаньоном на долгие годы.
                            Мы большое внимание уделяем гармоничному развитию каждого котёнка, поэтому котята обретают
                            новые дома исключительно по достижении трёхмесячного возраста, имея все документы, включая
                            ветеринарный паспорт. Каждый покупатель получает подробные инструкции по уходу и содержанию.
                            Мы всегда поддерживаем связь с новыми владельцами малышей и очень счастливы получать от них
                            новые известия.
                        </p>
                    </div>

                </div>
                <div className="bodyAbout">
                    <div className="txtAbout">
                        <h1>Наши племенные</h1>

                    </div>
                    <div className='iAbout'>
                        <img
                            className="catsA"
                            src={cat1}
                            alt='cat'
                        />
                        <h3>BLUE FREESIA'S ELVIS</h3>
                        <h5>Дата рождения: 24.03.2022</h5>
                        <p>Титул: CHAMPION WCF</p>
                    </div>
                    <div className='iAbout'>
                        <img
                            className="catsA"
                            src={cat2}
                            alt='cat'
                        />
                        <h3>DOMINIKA BLUE SHINE</h3>
                        <h5>Дата рождения: 14.06.2021</h5>
                        <p>Титул: CHAMPION WCF</p>
                    </div>
                    <Footer/>
                </div>

            </div>
        );
    }
}
