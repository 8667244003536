import React, {Component} from 'react';

export default class Footer extends Component {
    render() {
        return (
            <div className='fMail'>
                <h3 style={{
                }}>Blue Shine</h3>
                <p className='mail'>sofkovart@gmail.com</p>
            </div>
        );
    }
}

