import React, {Component} from 'react';
import {Map, YMaps} from "react-yandex-maps";

const mapState = {
    center: [45.03, 38.97], zoom: 14
};

export default class Maps extends Component{
    state = {showMap: true};

    toggleMap() {
        const {showMap} = this.state;
        this.setState({showMap: !showMap})
    }

    render() {
        const {showMap} = this.state;
        return (
            <div className="mMaps">
                <YMaps>
                    <div id="maps">
                        {showMap &&
                            <Map width="90vw" height="50vw" state={mapState}/>
                        }
                    </div>
                </YMaps>
            </div>
        );
    }
}

