import React, {Component} from 'react';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import {Button} from "react-bootstrap";
import catsR from "../Components/img/catsDoc1.png";
import catsM from "../Components/img/catsDoc2.png";

export default class Documents extends Component {
    render() {
        return (
            <div className='documBody'>
                <Header />
                <div className="docum">
                    <div className="rDocum">
                        <figure className="position-relative">
                            <img
                                className='catR'
                                src={catsR}
                                alt="catR"

                                style={{
                                    filter: "brightness(50%)",
                                }}

                            />
                            <figcaption className='txtCaption' >
                                Родословная
                            </figcaption>
                        </figure>
                        <Button style={{
                            color: '#2A7C6F',
                            borderColor: '#2A7C6F',
                            backgroundColor: 'white',
                            borderRadius: '10px'
                        }}>
                            Скачать >
                        </Button>
                    </div>
                    <div className="mDocum">
                        <figure className="position-relative">
                            <img
                                className="catM"
                                src={catsM}
                                alt='catM'
                                style={{
                                    filter: "brightness(50%)",

                                }}
                            />
                            <figcaption className="txtCaption" >
                                Метрика
                            </figcaption>
                        </figure>
                        <Button style={{
                            color: '#2A7C6F',
                            borderColor: '#2A7C6F',
                            backgroundColor: 'white',
                            borderRadius: '10px'
                        }}>
                            Скачать >
                        </Button>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

