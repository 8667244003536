import {getCats} from "./Contentful";
import {useEffect, useState} from "react";
import useFetch from "../useFetch";

const promise = getCats();

export function useData(){
    const [cats, setCats] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        promise.then(cats => {
            setCats(cats)
            setLoading(false)
        })
    }, []);
    return [cats, isLoading]
}
