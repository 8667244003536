import React, {Component} from 'react';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import cats1 from "../Pages/galleryImg/kittyGallery6.png";

class AboutTheBreed extends Component {
    render() {
        return (
            <div className="pageBody">
                <Header/>
                <div className="mainB">
                    <div className="bA">
                        <div>
                            <img
                                className="kityAb"
                                src={cats1}
                                alt='cat'
                            />
                        </div>
                        <div className="aboutPart">
                            <p>
                                В этом разделе вы сможете ознакомиться с краткой информацией о породе: истории
                                происхождения
                                русской голубой, внешности и стандартах породы, а также характере русской голубой
                                породы.
                            </p>
                        </div>
                    </div>
                    <div className="textAbout">
                        <h3>
                            История происхождения
                        </h3>
                        <p>
                            История происхождения русской голубой породы кошек имеет довольно древние корни. Изначально
                            эта порода называлась "Архангельская", так как ее представители были обнаружены в
                            Архангельской области России.

                            В XVIII веке английские и голландские купцы привезли этих кошек в Европу, где они вызвали
                            большой интерес и стали пользоваться популярностью. Они получили прозвище "русская голубая",
                            так как их шерсть имела особый оттенок синего цвета, напоминающий голубизну.

                            В начале XX века русская голубая порода почти исчезла из-за двух мировых войн и революции.
                            Однако, благодаря усилиям энтузиастов, в 1960-х годах удалось восстановить популяцию этих
                            кошек. Стандарт породы был разработан в 1971 году, и с тех пор русская голубая порода стала
                            одной из самых популярных и узнаваемых в мире.

                            Характеристики русской голубой породы включают в себя крепкое и компактное телосложение,
                            плотную шерсть с серебристым отливом и ярко-зеленые глаза. Они обладают дружелюбным и
                            спокойным характером, хорошо ладят с другими животными и детьми.

                            Русская голубая порода кошек стала популярной не только в России, но и во многих других
                            странах. Ее красота, элегантность и прекрасный характер делают ее привлекательным выбором
                            для многих кошачьих любителей.
                        </p>
                    </div>
                    <div className="textAbout1">
                        <h3>
                            Описание и стандарты породы
                        </h3>
                        <p>
                            Русская голубая кошка имеет привлекательную и изящную внешность, которая состоит из
                            следующих характеристик, согласно международной организации WCF:


                            1. Покров ее шерсти должен быть густым, плотным и мягким на ощупь. Она имеет двойное
                            покрытие шерсти, состоящее из короткого ворса и плотного подшерстка. Шерсть должна быть не
                            слишком жесткой и не слишком шелковистой.


                            2. Окрас кошки должен быть ровным и однородным. Голубой окрас, иногда с серебрением,
                            является характерным для русских голубых кошек. Окрас должен быть насыщенным и ярким.


                            3. Кошка должна иметь стройное телосложение с грациозными линиями. Она должна быть среднего
                            размера с хорошо развитой мускулатурой. Голова должна быть округлой, с прямым профилем и
                            выразительными большими глазами. Уши должны быть среднего размера, широко расставленные и
                            иметь конусовидную форму с кисточками на концах.


                            4. Лапы должны быть пропорциональными телу, прямыми и средней длины. Хвост должен быть
                            длинным и прямым, в меру пушистым.


                            5. Русские голубые кошки славятся своими глазами. Глаза кошки должны быть большими,
                            округлыми, зеленого цвета без примеси желтизны или других оттенков.


                            В целом, внешность русской голубой кошки включает в себя гармонию форм, цветов и линий,
                            подчеркивающих ее изящество и элегантность.
                        </p>
                    </div>
                    <div className="textAbout2">
                        <h3>
                            Характер
                        </h3>
                        <p>
                            Русская голубая порода кошек известна своим мягким и спокойным характером. Они обладают
                            уравновешенным и дружелюбным нравом, что делает их прекрасными спутниками для людей всех
                            возрастов.


                            Они не требуют постоянного внимания и обладают самодостаточным характером, хотя все же любят
                            быть в окружении своих хозяев. Русская голубая – идеальный вариант для семей с маленькими
                            детьми. Она никогда не переступит границы дозволенного: не поцарапает ребёнка и не испортит
                            вещи. Русские голубые в меру игривы, но обычно они удовлетворяются короткой физической
                            активностью, такой как игра с игрушками или просто пробежка по дому.


                            Русская голубая порода очень общительная и легко подружится с другими животными.

                            Они могут быть отличными компаньонами для одного человека или дружной семьи, которым нужна
                            надежная, добрая и спокойная кошка.
                        </p>
                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
}

export default AboutTheBreed;