export const Media = [
    {type: 'image', url: require('./kittyGallery1.png')},
    {type: 'image', url: require('./kittyGallery2.png')},
    {type: 'image', url: require('./kittyGallery3.png')},
    {type: 'image', url: require('./kittyGallery4.png')},
    {type: 'image', url: require('./kittyGallery5.png')},
    {type: 'image', url: require('./kittyGallery6.png')},
    {type: 'image', url: require('./kittyGallery7.png')},
    {type: 'image', url: require('./kittyGallery8.png')},
    {type: 'image', url: require('./kittyGallery9.png')}
]