import React from 'react';
import * as ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.css"
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";

const client = new ApolloClient({
    uri: 'http://localhost:1337/graphql',
    cache: new InMemoryCache()
})

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

      <BrowserRouter>
          <ApolloProvider client={client}>

          <Routes>
             <Route path="*" element={<App />} />
          </Routes>

          </ApolloProvider>
      </BrowserRouter>
);

