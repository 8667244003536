import React from 'react';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import {Media} from "./galleryImg/export";
import {useState} from "react";



export default function Gallery () {

    const[file, setFile] = useState(null);

        return (
            <div >
                <Header />
                <div className="galleryBody">
                    <div className="gallery">
                        <div className="media-container">
                        {
                            Media.map((file, index) => (
                                <div className="media" key={index} onClick={() => setFile(file)}>
                                    {
                                        file.type === 'image'
                                        ? <img src={file.url} alt="" />
                                        : <video src={file.url} muted />
                                    }
                                </div>
                            ))
                        }
                    </div>
                        <div className="popur-media" style={{
                            display: file ? 'block' : 'none'
                        }}>
                            <span onClick={() => setFile(null)}>&times;</span>
                            {
                                file?.type === 'video'
                                ? <video src={file?.url} muted autoPlay controls/>
                                : <img src={file?.url}/>
                            }
                        </div>
                    </div>
                <Footer />
                </div>
            </div>
        );
}
