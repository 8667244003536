import React from 'react';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import {useData} from "./api/useData";



export default function Kity() {

    const [cats, isLoading] = useData();

    return (
        <div>
            <Header/>
                <div className="catsBody">
                    <div className="mainH">
                        <h1>НАШИ КОТЯТА</h1>
                    </div>
                    <div className="cats-container">
                        {cats.map(cat => (
                            <div className="kitty-container" key={cat.id}>
                                <img src={cat.fields.image.fields.file.url} alt={cat.fields.name}/>
                                <h3>{cat.fields.name}</h3>
                                <p>{cat.fields.description}</p>
                            </div>
                        ))}
                    </div>
                    <Footer/>
                </div>
        </div>
    )

}

