import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./Pages/Home";
import {Route, Routes} from "react-router-dom";
import About from "./Pages/About";
import Kity from "./Pages/Kity";
import Gallery from "./Pages/Gallery";
import Documents from "./Pages/Documents";
import Contact from "./Pages/Contact";
import React from "react";
import AboutTheBreed from "./Pages/AboutTheBreed";


function App() {


  return (
    <div className="mainpage" style={{fontFamily: 'Playfair Display'}}>

        <Routes>
            <Route exact path="*" element={<Home />} />
            <Route path="/About" element={<About />} />
            <Route path="/AboutTheBreed" element={<AboutTheBreed />} />
            <Route path="/Kity" element={<Kity />} />
            <Route path="/Gallery" element={<Gallery />} />
            <Route path="/Documents" element={<Documents />} />
            <Route exact path="/Contact" element={<Contact />} />
        </Routes>

    </div>
  );
}

export default App;
