import React, {Component} from 'react';
import {Container, Navbar} from "@nextui-org/react";
import {Link} from "react-router-dom";


export default class Header extends Component {

    render() {
        const collapseItems = [
            {label: 'Главная', link: '*'},
            {label: 'О нас', link: '/About'},
            {label: 'Котята', link: '/Kity'},
            {label: 'Галерея', link: '/Gallery'},
            {label: 'Документы', link: '/Documents'},
            {label: 'Контакты', link: '/Contact'},
        ]
        return (
            <div className="pageBody">
                <Container className="headerBody" shouldHideOnScroll>
                    <p>BLUE SHINE</p>
                </Container>
                <Navbar className="navbarBody" isCompact shouldHideOnScroll isBordered variant="sticky" style={{
                    backgroundColor: "#4e7a8a",
                    width: '100vw',
                    color: 'white'
                }}>
                    <Navbar.Brand>
                        <Navbar.Toggle showIn="sm"/>
                    </Navbar.Brand>
                    <Navbar.Content className="navbarContent" hideIn="sm" style={{
                        marginLeft: '-24px',
                        marginRight: '-24px',
                    }}>
                        <Navbar.Link href="*"> Главная </Navbar.Link>
                        <Navbar.Link href="/About"> О нас </Navbar.Link>
                        <Navbar.Link href="/AboutTheBreed">О породе</Navbar.Link>
                        <Navbar.Link href="/Kity"> Котята </Navbar.Link>
                        <Navbar.Link href="/Gallery"> Галерея </Navbar.Link>
                        <Navbar.Link href="/Documents"> Документы </Navbar.Link>
                        <Navbar.Link href="/Contact"> Контакты </Navbar.Link>
                    </Navbar.Content>
                    <Navbar.Collapse disableAnimation classname="menu_sm" style={{
                        backgroundColor:"#bfd0d3"
                    }}>
                        {collapseItems.map((item) => (
                            <Navbar.CollapseItem style={{
                                border: "none"
                            }}
                                key={item.label}>
                                <Link to={item.link} style={{
                                    textDecoration:"none",
                                    color:"#808cab"
                                }}>
                                    {item.label}
                                </Link>
                            </Navbar.CollapseItem>
                        ))}

                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}
